import { createWebHistory, createRouter } from "vue-router";


import Home01 from "../pages/Home01" ;
import Home02 from "../pages/Home02" ;
import Home03 from "../pages/Home03" ;

import Explore01 from "../pages/Explore01" ;
import Explore02 from "../pages/Explore02" ;
import LiveAuction from "../pages/LiveAuction" ;
import ItemDetails01 from "../pages/ItemDetails01" ;

import Activity01 from "../pages/Activity01" ;
import Activity02 from "../pages/Activity02" ;

import AuthorDetails from "../pages/AuthorDetails";
import WalletConnect from "../pages/WalletConnect" ;
import Faq from "../pages/Faq";

import Blog from "../pages/Blog";
import BlogDetails from "../pages/BlogDetails";
import HelpCenter from "../pages/HelpCenter" ;

import Contact01 from "../pages/Contact01";
import Contact02 from "../pages/Contact02";


const routes = [

  { path: "/", name: "Home01", component: Home01 },
  { path: "/home-02", name: "Home02", component: Home02 },
  { path: "/home-03", name: "Home03", component: Home03 },

  { path: "/explore-01", name: "Explore01", component: Explore01 },
  { path: "/explore-02", name: "Explore02", component: Explore02 },
  { path: "/live-autions", name: "LiveAuction", component: LiveAuction },
  { path: "/item-details-01", name: "ItemDetails01", component: ItemDetails01 },

  { path: "/activity-01", name: "Activity01", component: Activity01 },
  { path: "/activity-02", name: "Activity02", component: Activity02 },

  { path: "/author/:slug", name: "AuthorDetails", component: AuthorDetails,  },
  { path: "/wallet-connect", name: "WalletConnect", component: WalletConnect },
  { path: "/faq", name: "Faq", component: Faq },

  { path: "/blog", name: "Blog", component: Blog },
  { path: "/single/:slug", name: "BlogDetails", component: BlogDetails, },
  { path: "/help-center", name: "HelpCenter", component: HelpCenter },

  { path: "/contact-01", name: "Contact01", component: Contact01 },
  { path: "/contact-02", name: "Contact02", component: Contact02 },


];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
