<template>
    <section class="tf-section today-pick" v-if="todaypicks !== null">
        <div class="themesflat-container">
            <div class="row" >
                <div class="col-md-12">
                    <div class="heading-live-auctions mg-bt-21">
                        <h2 class="tf-title pad-l-7">{{todaypicks.heading}}</h2>
                        <router-link :to="todaypicks.btnLink" class="exp style2">{{todaypicks.btnText}}</router-link>
                    </div>
                </div>

                <div  class="col-xl-3 col-lg-4 col-md-6 col-sm-6" v-for="item in todaypicks.Product" 
                    :key="item.id">
                    <div class="sc-card-product" :class="`${item.coomingsoon ? 'comingsoon' : ''}`">
                        <div class="card-media">
                            <router-link to="/item-details-01"> <img :src="item.image.data.attributes.url" alt="image"></router-link>
                            <router-link to="#" class="wishlist-button heart"><span class="number-like">100</span></router-link>
                            <div class="coming-soon">{{item.coomingsoon}}</div>
                        </div>
                        <div class="card-title">
                            <h5 class="style2"><router-link to="/item-details-01">"{{item.title}}"</router-link></h5>
                            <div class="tags">{{item.tag}}</div>
                        </div>
                        <div class="meta-info">
                            <div class="author">
                                <div class="avatar">
                                    <img :src="item.imgAuthor.data.attributes.url" alt="image">
                                </div>
                                <div class="info">
                                    <span>Owned By</span>
                                    <h6> <router-link to="/author/trista-francis">{{item.nameAuthor}}</router-link> </h6>
                                </div>
                            </div>
                            <div class="price">
                                <span>Current Bid</span>
                                <h5> {{item.price}}</h5>
                            </div>
                        </div>
                        <div class="card-bottom" :class="`${item.coomingsoon ? 'none' : ''}`">
                            <button class="sc-button style bag fl-button pri-3 no-bg" v-on:click="toggleActive(isActive)"><span>{{item.btnText}}</span></button>
                            <router-link to="/activity-01" class="view-history reload">{{item.btnView}}</router-link>
                        </div>
                    </div>
                </div>  

                <div class="col-md-12 wrap-inner load-more text-center"> 
                    <router-link to="#" id="load-more" class="sc-button loadmore fl-button pri-3" ><span>Load More</span></router-link>
                </div>

            </div>
        </div>
         <div class="modal fade popup" id="popup_bid" tabIndex="-1" role="dialog" aria-hidden="true"  :class="{ show: isActive }">
            <div class="overlay" v-on:click="toggleActive(isActive)"></div>
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" v-on:click="toggleActive(isActive)">  
                    </button>
                    <div class="modal-body space-y-20 pd-40" :class="{ show: isActive }">
                        <h3>Place a Bid</h3>
                        <p class="text-center">You must bid at least <span class="price color-popup">4.89 ETH</span>
                        </p>
                        <input type="text" class="form-control"
                            placeholder="00.00 ETH" />
                        <p>Enter quantity. <span class="color-popup">5 available</span>
                        </p>
                        <input type="number" class="form-control" placeholder="1" />
                        <div class="hr"></div>
                        <div class="d-flex justify-content-between">
                            <p> You must bid at least:</p>
                            <p class="text-right price color-popup"> 4.89 ETH </p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p> Service free:</p>
                            <p class="text-right price color-popup"> 0,89 ETH </p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p> Total bid amount:</p>
                            <p class="text-right price color-popup"> 4 ETH </p>
                        </div>
                        <router-link to="/wallet-connect" class="btn btn-primary" data-toggle="modal" data-target="#popup_bid_success" data-dismiss="modal" aria-label="Close"> Place a bid</router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'

export default {
    name: 'TodayPick',
    data (){
        return {
            todaypicks: null,
            isActive:false,
        }
        
    },
    created: async function (){
        const response = await axios.get('https://strapi-axies.onrender.com/api/today-pick?populate=Product.image,Product.imgAuthor')
        const { data: {attributes} } = response.data
        this.todaypicks = attributes
    },
     methods: {
        toggleActive(){
            this.isActive = !this.isActive;
        },
    }
}
</script>