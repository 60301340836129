<template>
    <div class="tf-section post-details">
        <div class="themesflat-container">
            <div class="wrap-flex-box style">
                <div class="post">
                    <div class="inner-content">
                        <h2 class="title-post">{{details[0].attributes.title}}</h2>
                        <div class="divider"></div>
                        <div class="meta-post flex mg-bt-31">
                            <div class="box">
                                <div class="inner">
                                    <h6 class="desc">DESIGNER INTERVIEW</h6>
                                    <p>{{details[0].attributes.authorname}}</p>
                                </div>
                            </div>
                            <div class="box left">
                                <div class="inner boder pad-r-50">
                                    <h6 class="desc">WRITER</h6>
                                    <p>DWINAWAN</p>
                                </div>
                                <div class="inner mg-l-39 mg-r-1">
                                    <h6 class="desc">DATE</h6>
                                    <p>{{details[0].attributes.date}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="image">
                            <img :src="details[0].attributes.image.data.attributes.url" alt="image">
                        </div> 
                        
                        <div class="inner-post mg-t-40">
                            <div v-html="details[0].attributes.longDesc"></div>
                            <div class="image-box">
                                <img :src="details[0].attributes.imgDetail1.data.attributes.url" alt="image">
                                <img :src="details[0].attributes.imgDetail2.data.attributes.url" alt="image">
                            </div>
                        </div>   
                        <div class="inner-post mg-t-22">
                            <div v-html="details[0].attributes.longDesc2"></div>
                            <div class="image">
                               <img :src="details[0].attributes.imgDetail3.data.attributes.url" alt="image">
                            </div>
                        </div>       
                        <div class="inner-post mg-t-24">
                            <div v-html="details[0].attributes.longDesc3"></div>                                 
                        </div>

                        <div class="sc-widget style-1">
                            <div class="widget widget-tag style-2">
                                <h4 class="title-widget">Tags</h4>
                                <ul>
                                    <li><router-link to="#">Bitcoin</router-link></li>
                                    <li><router-link to="#">Token</router-link></li>
                                    <li><router-link to="#">Wallet</router-link></li>
                                </ul>
                            </div>
                            <div class="widget widget-social style-2">
                                <h4 class="title-widget">Share:</h4>
                                <ul>
                                    <li><router-link to="#" class="icon-fl-facebook"></router-link></li>
                                    <li class="style-2"><router-link to="#" class="icon-fl-coolicon"></router-link></li>
                                    <li class="mgr-none"><router-link to="#" class="icon-fl-mess"></router-link></li>
                                </ul>
                            </div>
                        </div>    
                        <div class="divider d2"></div>
                        <div id="comments">
                            <h3 class="heading mg-bt-23">
                                Leave A Comment
                            </h3>
                            <form action="#" method="post" id="commentform" class="comment-form" >
                                <fieldset class="name">
                                    <input type="text" id="name" placeholder="Name" class="tb-my-input" name="name" tabIndex="2" aria-required="true" required />
                                </fieldset>
                                <fieldset class="email">
                                    <input type="email" id="email" placeholder="Email *" class="tb-my-input" name="email" tabIndex="2" aria-required="true" required />
                                </fieldset>
                                <fieldset class="message">
                                    <textarea id="message" name="message" rows="4" placeholder="Message" tabIndex="4" aria-required="true" required />
                                </fieldset>
                                <div class="btn-submit mg-t-36">
                                    <button class="tf-button-submit" type="submit">
                                        Send comment
                                </button>
                                </div>
                            </form>
                        </div>          
                    </div>
                </div>
                <div class="side-bar details">
                    <div class="widget widget-recent-post mg-bt-43">
                        <h3 class="title-widget mg-bt-23">Recent Post</h3>
                        <ul  v-if="blogs !== []">
                            <li 
                                class="box-recent-post"
                                v-for="blog in blogs.splice(0,4)"
                                :key="blog.id"
                                >
                                <div class="box-feature"><router-link :to="'/single/' + blog.attributes.slug">
                                    <img :src="blog.attributes.image.data.attributes.url" alt="image">
                                </router-link></div>
                                <div class="box-content">
                                        <router-link :to="'/single/' + blog.attributes.slug" class="title-recent-post">
                                            {{blog.attributes.title}}
                                        </router-link>
                                        <div><span class="sub-recent-post">{{blog.attributes.desc}}</span><router-link to="/blog" class="day-recent-post">{{blog.attributes.date}}</router-link></div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="widget widget-tag style-1">
                        <h3 class="title-widget mg-bt-23">Popular Tag</h3>
                        <ul><li><a class="box-widget-tag" href="#">Bitcoin</a></li><li><a class="box-widget-tag" href="#">NFT</a></li><li><a class="box-widget-tag" href="#">Bids</a></li><li><a class="box-widget-tag" href="#">Digital</a></li><li><a class="box-widget-tag" href="#">Arts</a></li><li><a class="box-widget-tag" href="#">Marketplace</a></li><li><a class="box-widget-tag" href="#">Token</a></li><li><a class="box-widget-tag" href="#">Wallet</a></li><li><a class="box-widget-tag" href="#">Crypto</a></li></ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import axios from 'axios'
export default {
    name: 'BlogDetails',
    props: ['detailsContent'],
    data: function () {
        return {
            blogs: [],
            details: this.detailsContent
        }
    },
    created: async function (){
        const response = await axios.get('https://strapi-axies.onrender.com/api/blogs?populate=*', {params: {_limit: 4}})
        this.blogs = response.data.data
    },
}
</script>