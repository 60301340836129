<template>
  <div class="tab-content" v-if="bid !== null">
    <p>{{bid.Provenance}}</p>
  </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Provenance',
    data (){
        return {
            bid: null
        }
    },
    created: async function (){
        const response = await axios.get('https://strapi-axies.onrender.com/api/tab?populate=Bid.imgAuthor')
        const { data: {attributes} } = response.data
        this.bid = attributes
    },
}
</script>