<template>
    <section class="tf-section top-seller bg-home-3" >
        <div class="themesflat-container" v-if="blogs !== []">
            <div class="row">
                <div class="col-md-12">
                    <div >
                        <h2 class="tf-title">
                            Top Seller</h2>
                        <div class="heading-line s1"></div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6" v-for="blog in moreblogs" 
                        :key="blog.id">
                    <div class="sc-author-box">
                        <div class="author-avatar">
                            <router-link to="/author/trista-francis">
                                <img :src="blog.attributes.image.data.attributes.url" alt="image" class="avatar">
                            </router-link>
                            <div class="badge"><i class="ripple"></i></div>
                        </div>
                        <div class="author-infor">
                            <h5 class="style2"><router-link :to="'/author/' + blog.attributes.slug">{{blog.attributes.title}}</router-link></h5>
                            <span class="price">{{blog.attributes.price}}</span>
                        </div>
                    </div>    
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';


export default  ({
    name: 'TopSeller',
    data (){
        return{
            blogs: [],
            moreblogs: [],
        }
    },
    created: async function (){
        const response = await axios.get('https://strapi-axies.onrender.com/api/authors?populate=*')
        this.blogs = response.data.data
        this.moreblogs = this.blogs.slice(0,12)
    },
})
</script>