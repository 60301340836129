<template>
    <div class="mainslider home">
        <div class="flat-title-page">
            <img class="bgr-gradient gradient1" src="../../assets/images/backgroup-secsion/bg-gradient1.png" alt="Axies">
            <img class="bgr-gradient gradient2" src="../../assets/images/backgroup-secsion/bg-gradient2.png" alt="Axies">
            <img class="bgr-gradient gradient3" src="../../assets/images/backgroup-secsion/bg-gradient3.png" alt="Axies">
            <div class="shape item-w-16"></div><div class="shape item-w-22"></div>
            <div class="shape item-w-32"></div>
            <div class="shape item-w-48">
            </div><div class="shape style2 item-w-51">
            </div><div class="shape style2 item-w-51 position2"></div>
            <div class="shape item-w-68"></div>
            <div class="overlay"></div>
            <carousel
                
                :wrap-around="false"
                v-if="banner !== null"
            >
                <Slide 
                    v-for="slide in banner.slidesItem" 
                    :key="slide.id"
                >
                    <div class="slider-item">	
                        <div class="themesflat-container ">
                            <div class="wrap-heading flat-slider flex">
                                <div class="content">
                                    <div v-html="slide.heading"></div>
                                    <p class="sub-heading">{{slide.desc}}
                                    </p>
                                    <div class="flat-bt-slider flex style2" >
                                        <router-link :to="slide.btnLink" class="sc-button header-slider style style-1 rocket fl-button pri-1"  >
                                            <span>{{slide.btnText}}</span>
                                        </router-link>
                                        <router-link :to="slide.btnLink2" class="sc-button header-slider style style-1 note fl-button pri-1">
                                            <span>{{slide.btnText2}}</span>
                                        </router-link>
                                    </div>
                                </div>
                                <div class="image">
                                    <img class="img-bg" src="../../assets/images/backgroup-secsion/img-bg-sliderhome2.png" alt="axies">
                                    <img :src="slide.image.data.attributes.url" alt="image">
                                </div>
                            </div>   
                        </div>					                           
                    </div>
                </Slide>
                
                <template #addons>
                    <Navigation  />
                </template> 
            </carousel>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation   } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'Slider',
    components: {
        Carousel,
        Slide,
        Navigation ,
    },
    data: () => ({
        banner: null,
    }),
    created: async function (){
        const response = await axios.get('https://strapi-axies.onrender.com/api/bannerhomeone?populate=slidesItem.image')
        const { data: {attributes} } = response.data
        this.banner = attributes
    },
})
</script>