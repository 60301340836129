<template>

    <section class="tf-box-icon create style1 tf-section">
        <div class="themesflat-container"  v-if="create !== null">
            <div class="row">
                <div class="col-md-12">
                    <div class="heading-live-auctions mg-bt-22">
                        <h2 class="tf-title pb-17">
                            {{create.heading}}</h2>                         
                    </div>
                </div>
                <div class="box-create row col-lg-12">
                    <div class='col-lg-3 col-md-6 col-12'  v-for="create in create.CreateAndSell"
                        :key="create.id" >
                        <div class="sc-box-icon">
                            <div class="image center">
                                <div class='icon-create'>
                                    <img :src="create.icon.data.attributes.url" alt="image">
                                </div>                                                                             
                            </div>
                            <h3 class="heading"> 
                                <router-link :to="create.link">
                                {{create.title}}
                                </router-link></h3>
                            <p class="content">{{create.desc}}</p>
                        </div>
                    </div>
                </div>
            </div>                 
        </div>
    </section>
   
</template>

<script>
import axios from 'axios'

export default {
    name: 'CreateAndSell',
    data (){
        return {
            create: null
        }
    },
    created: async function (){
        const response = await axios.get('https://strapi-axies.onrender.com/api/create-and-sell?populate=CreateAndSell.icon')
        const { data: {attributes} } = response.data
        this.create = attributes
    },
}
</script>