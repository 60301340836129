<template>
    <section class="tf-section top-seller">
        <div class="themesflat-container" v-if="blogs !== []">
            <div class="row">
                <div class="col-md-12">
                    <div class="heading-live-auctions">
                        <h2 class="tf-title">
                            Top Seller</h2>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="tf-box " >
                        <div class="box-item" v-for="blog in blogs.slice(0,10)" 
                        :key="blog.id">
                            <div class="sc-author-box style-3 pd-0">
                                <div class="author-avatar">
                                    <router-link to="/author/trista-francis">
                                        <img :src="blog.attributes.image.data.attributes.url" alt="image" class="avatar">
                                    </router-link>
                                    <div class="badge"><i class="ripple"></i></div>
                                </div>
                                <div class="author-infor">
                                    <h5 class="fs-16"><router-link :to="'/author/' + blog.attributes.slug">
                                                    {{blog.attributes.title}}
                                                </router-link></h5>
                                    <span class="price">{{blog.attributes.price}}</span>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';


export default  ({
    name: 'TopSeller',
    data (){
        return{
            blogs: [],
        }
    },
    created: async function (){
        const response = await axios.get('https://strapi-axies.onrender.com/api/authors?populate=*')
        this.blogs = response.data.data
    },
})
</script>