<template>
    <div class="tf-section sc-explore-2 " v-if="todaypicks !== null">
        <div class="themesflat-container">
            <div class="row">
                <div class="col-md-12">
                    <div class="seclect-box style3">
                        <div class="aselect" :data-value="value4" :data-list="skills3">
                            <div class="selector" @click="toggle4()">
                                <div class="label">
                                        <span>{{ value4 }}</span>
                                </div>
                                <div class="arrow fa fa-chevron-down" :class="{ expanded : visible4 }"></div>
                                <div :class="{ hidden : !visible4, visible4 }">
                                    <ul>
                                        <li :class="{ current : item === value4 }" v-for="item in skills3" :key="item.id" @click="select4(item)">{{ item }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="aselect" :data-value="value5" :data-list="skills4">
                            <div class="selector" @click="toggle5()">
                                <div class="label">
                                        <span>{{ value5 }}</span>
                                </div>
                                <div class="arrow fa fa-chevron-down" :class="{ expanded : visible5 }"></div>
                                <div :class="{ hidden : !visible5, visible5 }">
                                    <ul>
                                        <li :class="{ current : item === value5 }" v-for="item in skills4" :key="item.id" @click="select5(item)">{{ item }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <ul class="filter">
                        <li  v-on:click="selectedCategory ='All'" :class="{active:selectedCategory == 'All'}" > All</li>
                        <li v-for="item in unique" :key="item.id" :class="{active:selectedCategory == item.category}" v-on:click="selectedCategory = item.category" > {{item.category}} </li>
                    </ul>
                    <div class="row">

                            <div  class="col-xl-3 col-lg-4 col-md-6 col-sm-6"  v-for="item in filtered" 
                                        :key="item.id" >
                                <div class="sc-card-product" :class="`${item.coomingsoon ? 'comingsoon' : ''}`">
                                    <div class="card-media">
                                        <router-link to="/item-details-01"> <img :src="item.image.data.attributes.url" alt="image"></router-link>
                                        <router-link to="#" class="wishlist-button heart"><span class="number-like">100</span></router-link>
                                        <div class="coming-soon">{{item.coomingsoon}}</div>
                                    </div>
                                    <div class="card-title">
                                        <h5 class="style2"><router-link to="/item-details-01">"{{item.title}}"</router-link></h5>
                                        <div class="tags">{{item.tag}}</div>
                                    </div>
                                    <div class="meta-info">
                                        <div class="author">
                                            <div class="avatar">
                                                <img :src="item.imgAuthor.data.attributes.url" alt="image">
                                            </div>
                                            <div class="info">
                                                <span>Owned By</span>
                                                <h6> <router-link to="/author/trista-francis">{{item.nameAuthor}}</router-link> </h6>
                                            </div>
                                        </div>
                                        <div class="price">
                                            <span>Current Bid</span>
                                            <h5> {{item.price}}</h5>
                                        </div>
                                    </div>
                                    <div class="card-bottom" :class="`${item.coomingsoon ? 'none' : ''}`">
                                        <button class="sc-button style bag fl-button pri-3 no-bg" v-on:click="toggleActive(isActive)"><span>{{item.btnText}}</span></button>
                                        <router-link to="/activity-01" class="view-history reload">{{item.btnView}}</router-link>
                                    </div>
                                </div>
                            </div> 
                            <div class="col-md-12 wrap-inner load-more text-center"> 
                                <router-link to="#" id="load-more" class="sc-button loadmore fl-button pri-3" ><span>Load More</span></router-link>
                            </div> 
                    </div> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios'

export default {
    name: 'Filter',
    created: async function (){
    const response = await axios.get('https://strapi-axies.onrender.com/api/today-pick?populate=Product.image,Product.imgAuthor')
    const { data: {attributes} } = response.data
    this.todaypicks = attributes
    },
    data: function (){
        return {
            todaypicks: null,
            selectedCategory: "All",
            visible4: false,
            visible5: false,
            value4: 'All Artworks',
            skills3: ["All Artworks","Abstraction","Skecthify","Patternlicious","Virtuland","Papercut"],
            value5: 'Sort by',
            skills4: ["Sort by","Top rate","Mid rate","Low rate"],
        }
    },
    methods: {
        toggleActive(){
            this.isActive = !this.isActive;
        },
       
         toggle4() {
			this.visible4 = !this.visible4;
		},
		select4(option) {
			this.value4 = option;
		},
        toggle5() {
			this.visible5 = !this.visible5;
		},
		select5(option) {
			this.value5 = option;
		},
    },
    computed: {
        filtered: function() {
			var category = this.selectedCategory;
			
			if(category === "All") {
				return this.todaypicks.Product;
			} else {
				return this.todaypicks.Product.filter(function(item) {
					return item.category === category;
				});
			} 
		},
        unique: function(){
            var arr = this.todaypicks.Product;
            var newArr = []
            newArr = arr.filter(function (item) {
                return newArr.includes(item.category) ? '' : newArr.push(item.category)
            })
            return newArr
        }
    }
}

</script>