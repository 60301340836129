<template>
    <div class="authors-2">
        <Header />
        <PageTitle 
            v-if="details !== null"
            :pageTitle="details[0].attributes.title"
            pageTitleActive ="Community"
            link = "blog"
        />
        <div v-if="details !== null">
            <AuthorDetails 
                v-bind:detailsContent="details"
            />
        </div>
        <Footer />
    </div>
</template>

<script>

import Header from '../components/Header/Header'
import PageTitle from '../components/PageTitle/PageTitle'
import AuthorDetails from '../components/AuthorDetails/AuthorDetails'
import Footer from '../components/Footer/Footer'

import axios from 'axios'

export default {
    name: 'AuthorDetailsPage',
    components: {
        Header,
        PageTitle,
        AuthorDetails,
        Footer,
    },
    data (){
        return {
            details: null
        }
    },
    created: async function (){
        const { slug } = this.$route.params
        const reaponse = await axios.get(`https://strapi-axies.onrender.com/api/authors?filters[slug][$eq]=${slug}&populate=*`, { params: { slug }})
        this.details = reaponse.data.data
    }
}
</script>